var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "rootRef", class: "rootVM " + (_vm.show ? "" : "hide") },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.detailsItem.cctvInstallPlaceCont) +
                  " ( " +
                  _vm._s(_vm.detailsItem.occurDtm) +
                  _vm._s(
                    "" +
                      (_vm.detailsItem.dangerLiftDtm
                        ? " ~ " + _vm.detailsItem.dangerLiftDtm
                        : "")
                  ) +
                  ", "
              ),
              _c(
                "span",
                { class: _vm.detailsItem.misdectDtm ? "misdect" : "" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.detailsItem.misdectDtm
                        ? "오탐"
                        : _vm.detailsItem.videoActnDvsnCdNm
                    )
                  )
                ]
              ),
              _vm._v(" ) ")
            ]),
            _c("i", {
              staticClass: "fa-solid fa-times",
              on: { click: _vm.onClose }
            })
          ]),
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _vm._l(_vm.frameDetails, function(object, i) {
                return _c("div", { key: i, staticClass: "object" }, [
                  _vm._v(
                    " " +
                      _vm._s(object.objectNm) +
                      " " +
                      _vm._s(
                        object.trespsDtctTimeNm
                          ? " - " + object.trespsDtctTimeNm
                          : ""
                      ) +
                      " "
                  ),
                  !object.trespsDtctTimeNm && object.targetNms[0]
                    ? _c(
                        "span",
                        [
                          _vm._v(" [ "),
                          _vm._l(object.targetNms, function(targetNm, j) {
                            return _c(
                              "span",
                              { key: j, staticClass: "target" },
                              [_vm._v(_vm._s(targetNm))]
                            )
                          }),
                          _vm._v(" ] ")
                        ],
                        2
                      )
                    : _vm._e(),
                  !object.trespsDtctTimeNm &&
                  !object.targetNms[0] &&
                  object.targetNm
                    ? _c("span", [
                        _vm._v(" [ "),
                        _c("span", { staticClass: "target" }, [
                          _vm._v(_vm._s(object.targetNm))
                        ]),
                        _vm._v(" ] ")
                      ])
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(object.targetStat === "W" ? " 주의" : " 위험") +
                      " " +
                      _vm._s(object.regDtm) +
                      " "
                  )
                ])
              }),
              _c(
                "button",
                {
                  ref: "toggleBtnRef",
                  class: "toggle " + (_vm.toggleVideo ? "active" : ""),
                  on: { click: _vm.clickToggle }
                },
                [
                  _c("label", {
                    staticClass: "switch",
                    on: { click: _vm.clickToggle }
                  }),
                  _vm._v(" 비디오로 보기 ")
                ]
              )
            ],
            2
          )
        ]),
        _vm.toggleVideo && _vm.detailsItemVideo.cctvId
          ? _c(
              "div",
              { staticClass: "video-body" },
              [
                _c("video-box", {
                  key: _vm.keyForRerendering,
                  attrs: {
                    detailsItem: _vm.detailsItemVideo,
                    urlFlag: _vm.detailsItemVideo.videoWarnActnDvsnCd
                      ? "warn-work"
                      : "danger",
                    imgWrapper: _vm.detailsItemVideo.videoWarnActnDvsnCd
                      ? _vm.detailsItemVideo.inqWarnWorkOccurImgOutVOs
                      : _vm.detailsItemVideo.inqDangerOccurFrameImgOutVOs
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.toggleVideo,
                expression: "!toggleVideo"
              }
            ],
            staticClass: "frame-body"
          },
          [
            _c("div", { staticClass: "img-wrapper" }, [
              _c("i", {
                staticClass: "fa-solid fa-chevron-left",
                on: { click: _vm.clickPrevOneFrame }
              }),
              _c("i", {
                staticClass: "fa-solid fa-chevron-right",
                on: { click: _vm.clickNextOneFrame }
              }),
              _vm.show && _vm.activeImgSrc
                ? _c("img", {
                    staticClass: "active-img",
                    attrs: { src: _vm.activeImgSrc }
                  })
                : _vm._e()
            ]),
            _c("div", { staticClass: "img-list-wrapper" }, [
              _c("i", {
                staticClass: "fa-solid fa-chevron-left",
                on: {
                  click: function($event) {
                    return _vm.clickMoveManyFrame($event, -1)
                  }
                }
              }),
              _c("i", {
                staticClass: "fa-solid fa-chevron-right",
                on: { click: _vm.clickMoveManyFrame }
              }),
              _c(
                "div",
                { ref: "frameListRef", staticClass: "img-list" },
                [
                  _vm._l(_vm.frames, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class:
                          "img-wrapper " +
                          (_vm.activeIndex === index ? "active" : ""),
                        on: {
                          click: function($event) {
                            return _vm.clickFrame($event, index)
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: item.imgData || "/images/noimage.png" }
                        })
                      ]
                    )
                  }),
                  _c("i", { staticClass: "fa-solid fa-spin fa-spinner" }),
                  _c("span", { staticClass: "end-content" }, [
                    _vm._v("마지막 프레임입니다")
                  ])
                ],
                2
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }