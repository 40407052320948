<template>
	<div :class="`rootVM ${show ? '' : 'hide'}`" ref="rootRef">
		<div class="content">
			<div class="header">
				<div class="top">
					<div class="text">
						{{ detailsItem.cctvInstallPlaceCont }} ( {{ detailsItem.occurDtm
						}}{{ `${detailsItem.dangerLiftDtm ? ` ~ ${detailsItem.dangerLiftDtm}` : ''}` }},
						<span :class="detailsItem.misdectDtm ? 'misdect' : ''">{{ detailsItem.misdectDtm ? '오탐' : detailsItem.videoActnDvsnCdNm }}</span>
						)
					</div>
					<i class="fa-solid fa-times" @click="onClose" />
				</div>
				<div class="bottom">
					<div class="object" v-for="(object, i) in frameDetails" :key="i">
						{{ object.objectNm }} {{ object.trespsDtctTimeNm ? ` - ${object.trespsDtctTimeNm}` : '' }}
						<span v-if="!object.trespsDtctTimeNm && object.targetNms[0]">
							[
							<span class="target" v-for="(targetNm, j) in object.targetNms" :key="j">{{ targetNm }}</span>
							]
						</span>
						<span v-if="!object.trespsDtctTimeNm && !object.targetNms[0] && object.targetNm">
							[
							<span class="target">{{ object.targetNm }}</span>
							]
						</span>
						{{ object.targetStat === 'W' ? ' 주의' : ' 위험' }}
						{{ object.regDtm }}
					</div>
					<button :class="`toggle ${toggleVideo ? 'active' : ''}`" @click="clickToggle" ref="toggleBtnRef">
						<label class="switch" @click="clickToggle" />
						비디오로 보기
					</button>
				</div>
			</div>
			<div class="video-body" v-if="toggleVideo && detailsItemVideo.cctvId">
				<video-box
					:key="keyForRerendering"
					:detailsItem="detailsItemVideo"
					:urlFlag="detailsItemVideo.videoWarnActnDvsnCd ? 'warn-work' : 'danger'"
					:imgWrapper="
						detailsItemVideo.videoWarnActnDvsnCd ? detailsItemVideo.inqWarnWorkOccurImgOutVOs : detailsItemVideo.inqDangerOccurFrameImgOutVOs
					"
				></video-box>
			</div>

			<div v-show="!toggleVideo" class="frame-body">
				<div class="img-wrapper">
					<i class="fa-solid fa-chevron-left" @click="clickPrevOneFrame" />
					<i class="fa-solid fa-chevron-right" @click="clickNextOneFrame" />
					<img v-if="show && activeImgSrc" class="active-img" :src="activeImgSrc" />
				</div>
				<div class="img-list-wrapper">
					<i class="fa-solid fa-chevron-left" @click="clickMoveManyFrame($event, -1)" />
					<i class="fa-solid fa-chevron-right" @click="clickMoveManyFrame" />
					<div class="img-list" ref="frameListRef">
						<div
							:class="`img-wrapper ${activeIndex === index ? 'active' : ''}`"
							v-for="(item, index) in frames"
							:key="index"
							@click="clickFrame($event, index)"
						>
							<img class="img" :src="item.imgData || '/images/noimage.png'" />
						</div>
						<i class="fa-solid fa-spin fa-spinner" />
						<span class="end-content">마지막 프레임입니다</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VideoBox from './VideoBox.vue';
import apiIndex from '../api/index';

const dashboardUrl = apiIndex.dashboard;
const dangerOccurUrl = apiIndex.dangerOccur;
const warnWorkOccurUrl = apiIndex.warnWorkOccur;
const dangerWarnWorkOccurUrl = apiIndex.dangerWarnWorkOccur;
let axiosExtention;

export default {
	components: { VideoBox },
	props: {
		show: { type: Boolean, required: true },
		onClose: { type: Function, required: true },
		detailsItem: { type: Object, required: true },
		imgIndex: { type: Number, required: false },
	},
	data: () => ({
		options: {},
		page: 0,
		activeIndex: 0,
		activeImgSrc: '',
		toggleVideo: false,
		frames: [],
		frameDetails: [],
		keyForRerendering: 0,
		detailsItemVideo: {},
	}),
	async created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		let commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		if (!commonCodeList) {
			await this.$axios
				.post(apiIndex.commCode.inqCommCodeAllList, {})
				.then(
					function (r) {
						commonCodeList = r.data;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		}

		this.options = {
			videoDangerActnDvsnCdOption: commonCodeList.filter(item => item.grpCd == 'video_danger_actn_dvsn_cd'),
			videoWarnActnDvsnCdOption: commonCodeList.filter(item => item.grpCd == 'video_warn_actn_dvsn_cd'),
		};
	},
	async mounted() {
		// 좌우 프레임 이동 키 이벤트 등록
		window.addEventListener('keydown', e => {
			if (this.toggleVideo || !this.show) return;
			e.stopPropagation();

			switch (e.code) {
				case 'ArrowLeft':
					return this.clickPrevOneFrame();
				case 'ArrowRight':
					return this.clickNextOneFrame();
			}
		});
	},
	watch: {
		async show() {
			if (this.show) {
				// 팝업창 열리면 데이터 세팅
				if (this.detailsItem.videoWarnActnDvsnCd) {
					// 주의
					this.options.videoWarnActnDvsnCdOption.map(code => {
						if (code.value === this.detailsItem.videoWarnActnDvsnCd) {
							this.detailsItem.videoActnDvsnCdNm = code.text;
						}
					});
					this.detailsItem.occurDtm = this.detailsItem.warnWorkOccurDtm;
				} else {
					// 위험
					this.options.videoDangerActnDvsnCdOption.map(code => {
						if (code.value === this.detailsItem.videoDangerActnDvsnCd) {
							this.detailsItem.videoActnDvsnCdNm = code.text;
						}
					});
					this.detailsItem.occurDtm = this.detailsItem.dangerOccurDtm;
				}

				// 오른쪽 스크롤 위치가 전체의 90% 지점에 도달하면 데이터 추가 로드
				this.$refs.frameListRef.addEventListener('scroll', e => {
					const curPosition = e.target.scrollLeft + e.target.offsetWidth;
					if (curPosition / e.target.scrollWidth >= 0.9) {
						this.getFrames();
					}
				});
				await this.getFrames();
				this.clickFrame(null, this.imgIndex || 0);

				document.querySelector('html').style.overflow = 'hidden';
				document.querySelector('body').classList.add('no-blockui');
			} else {
				// 팝업창 닫히면 데이터 초기화
				this.initPopup();
				document.querySelector('html').style.overflow = 'auto';
				document.querySelector('body').classList.remove('no-blockui');
			}
		},
	},
	destroyed() {
		document.querySelector('html').style.overflow = 'auto';
		document.querySelector('body').classList.remove('no-blockui');
	},
	methods: {
		initPopup() {
			// 팝업창 닫히면 데이터 초기화
			this.frames = [];
			this.frameDetails = [];
			this.page = 0;
			this.activeIndex = 0;
			this.activeImgSrc = '';
			this.toggleVideo = false;
			this.detailsItemVideo = {};
			this.$refs.frameListRef.classList.remove('end');
		},
		// 이미지 목록 데이터 fetch
		async getFrames() {
			const classList = this.$refs.frameListRef.classList;

			// 현재 데이터를 fetch 중이거나 마지막이면 동작 무시
			if (['loading-list', 'end'].some(className => classList.contains(className))) return;
			classList.add('loading-list');

			const pageSize = 30;
			const { data } = await this.$axios.post(dashboardUrl.popupImage + `?page=${this.page++}&size=${pageSize}`, {
				strtFrameImgSeq: this.detailsItem.strtFrameImgSeq,
				endFrameImgSeq: this.detailsItem.endFrameImgSeq,
				siteId: this.detailsItem.siteId,
				cctvId: this.detailsItem.cctvId,
				objectId: this.detailsItem.objectId,
				targetId: this.detailsItem.targetId,
				isWarnWork: this.detailsItem.videoWarnActnDvsnCd ? true : false,
			});

			if (data) {
				data.content.forEach(e => {
					e.imgData = `data:image/jpg;base64,${e.imgByte}`;
				});
				this.frames = [...this.frames, ...data.content];
			}

			if (pageSize > data.content.length) {
				classList.add('end');
			}

			classList.remove('loading-list');
		},
		//이미지 데이터 세팅(비디오)
		setAllFramesToImageData() {
			if (this.detailsItem.videoWarnActnDvsnCd) {
				// 주의
				this.detailsItemVideo.inqWarnWorkOccurImgOutVOs.forEach(e => {
					this.$axios.post(warnWorkOccurUrl.imgOneWarnWorkOccur, { fileName: e.imgSaveFileNm }).then(response => {
						e.imgData = `data:image/jpg;base64,${response.data.imgByte}`;
					});
				});
			} else {
				// 위험
				this.detailsItemVideo.inqDangerOccurFrameImgOutVOs.forEach(e => {
					this.$axios.post(dangerOccurUrl.imgOneDangerOccur, { fileName: e.imgSaveFileNm }).then(response => {
						e.imgData = `data:image/jpg;base64,${response.data.imgByte}`;
					});
				});
			}
		},
		// 한 프레임에 발생한 모든 대상물/조치대상 조회
		async getOneObjectTargets(frameImgSeq) {
			await this.$axios
				.post(dangerWarnWorkOccurUrl.ingOneObjectTargetInfo, {
					siteId: this.detailsItem.siteId,
					cctvId: this.detailsItem.cctvId,
					strtFrameImgSeq: this.detailsItem.strtFrameImgSeq,
					endFrameImgSeq: this.detailsItem.endFrameImgSeq,
					frameImgSeq,
					isWarnWork: this.detailsItem.videoWarnActnDvsnCd ? true : null,
				})
				.then(res => {
					this.frameDetails = res.data;
				});
		},
		// 이 이벤트에서 발생한 모든 대상물/조치대상 조회
		async getAllObjectTargets() {
			this.$axios
				.post(dangerWarnWorkOccurUrl.ingAllObjectTargetInfo, {
					siteId: this.detailsItem.siteId,
					cctvId: this.detailsItem.cctvId,
					strtFrameImgSeq: this.detailsItem.strtFrameImgSeq,
					endFrameImgSeq: this.detailsItem.endFrameImgSeq,
				})
				.then(res => {
					this.frameDetails = res.data;
				});
		},
		// 비디오 생성에 필요한 데이터 조회
		async getVideoData() {
			const url = this.detailsItem.videoWarnActnDvsnCd ? warnWorkOccurUrl.inqOneWarnWorkOccurVideo : dangerOccurUrl.inqOneDangerOccurVideo;
			let req;
			if (this.detailsItem.occurSeq) {
				req = {
					...this.detailsItem,
					dangerOccurObjectSeq: this.detailsItem.warnWork ? null : Number(this.detailsItem.occurSeq),
					warnWorkOccurSeq: this.detailsItem.warnWork ? this.detailsItem.occurSeq : null,
					inqWarnWorkOccurImgOutVOs: null,
					inqDangerOccurFrameImgOutVOs: null,
				};
			} else {
				req = {
					...this.detailsItem,
					inqWarnWorkOccurImgOutVOs: null,
					inqDangerOccurFrameImgOutVOs: null,
				};
			}
			const { data } = await this.$axios.post(url, req);

			this.detailsItemVideo = data;
			this.detailsItemVideo.occurObjectSeq = this.detailsItem.occurObjectSeq;
			this.setAllFramesToImageData(); // 이미지 데이터 세팅
		},
		// 비디오 토글 버튼 클릭
		async clickToggle(e) {
			e.stopPropagation();
			this.toggleVideo = !this.$refs.toggleBtnRef.classList.contains('active');
			this.keyForRerendering++;

			if (this.toggleVideo) {
				// 비디오로 보기라면..
				// 이 이벤트에서 발생한 위험/주의 대상물/조치대상 조회
				this.getAllObjectTargets();
				// 비디오 데이터 요청
				this.getVideoData();
			} else {
				// 프레임 보기라면..
				// 이전에 active 되어 있던 프레임 클릭
				this.clickFrame(null, this.activeIndex);
			}
		},
		// 이전 프레임 하나 이동 버튼 클릭
		clickPrevOneFrame() {
			const index = Math.max(0, this.activeIndex - 1);
			this.clickFrame(null, index);
		},
		// 다음 프레임 하나 이동 버튼 클릭
		clickNextOneFrame() {
			const index = Math.min(this.frames.length - 1, this.activeIndex + 1);
			this.clickFrame(null, index);
		},
		// 스크롤 거리 반환
		getScrollDistance() {
			const frameListRef = this.$refs.frameListRef;
			const frameWidth = frameListRef.children[0].offsetWidth;
			const frameWidthWithGap = frameWidth + 12; // 실제 프레임이 차지하고 있는 길이
			return frameListRef.offsetWidth - frameWidthWithGap - 50; // 스크롤 거리
		},
		// img list 현재 뷰포트에서 첫 번째 프레임 또는 마지막 프레임을 찾아서 클릭
		clickElemForMoveMany(direction = 1) {
			const frameListRef = this.$refs.frameListRef;
			const childrenList = frameListRef.children; // 현재 프레임 리스트
			const frameWidth = frameListRef.children[0].offsetWidth; // 프레임 하나의 가로 길이

			// 전체 프레임 리스트 루프
			for (let i = 0; i < childrenList.length; i++) {
				const left = frameListRef.scrollLeft; // img list의 현재 왼쪽 스크롤 위치
				const frameLeft = childrenList[i].offsetLeft; // 현재 루프 elem의 스크롤 left 위치

				// 왼쪽 이동이고, 스크롤이 맨 처음이면 첫 번째 클릭
				if (direction < 0 && left < frameWidth) {
					return this.clickFrame(null, 0);
				}

				// 오른쪽 이동이고, 리스트가 마지막이고, 스크롤이 한 줄만 남았을 땐 마지막 요소를 클릭
				const totalScrollWidth = frameListRef.scrollWidth; // 전체 스크롤 길이
				const remainScrollWidth = totalScrollWidth - (left + frameWidth); // 남은 스크롤 길이 = 전체 - (현재 왼쪽 위치 + img list 길이)
				if (direction && frameListRef.classList.contains('end') && remainScrollWidth < frameListRef.offsetWidth) {
					return this.clickFrame(null, this.frames.length - 1);
				}

				// 현재 뷰포트 기준 가운데 요소 클릭
				const halfWidth = frameListRef.offsetWidth / 2 + left; // 현재 뷰포트 기준 가운데 left 위치
				if (frameLeft < halfWidth && frameLeft + frameWidth > halfWidth) {
					return this.clickFrame(null, i);
				}

				// 현재 뷰포트에서 첫 번째 요소 클릭
				// if (left <= frameLeft && left + frameWidth > frameLeft) {
				// 	return this.clickFrame(null, i);
				// }
			}
		},
		// 여러 프레임 이동 버튼 클릭
		// direction: 1=오른쪽(default), -1=왼쪽
		async clickMoveManyFrame(e, direction = 1) {
			const frameListRef = this.$refs.frameListRef;
			const frameListWidth = this.getScrollDistance();

			// 스크롤 중일 땐 무시
			if (frameListRef.classList.contains('busy')) return;

			// 스크롤 lock
			frameListRef.classList.add('busy');
			// 스크롤 실행
			frameListRef.scrollBy(frameListWidth * direction, 0);

			// 스크롤 후처리
			setTimeout(async () => {
				await this.clickElemForMoveMany(direction);
				frameListRef.classList.remove('busy');
			}, 600);
		},
		// 프레임 목록에서 프레임 클릭
		async clickFrame(e, index) {
			const classList = this.$refs.frameListRef.classList;

			// if (classList.contains('loading-details')) return;

			this.activeIndex = index;
			this.$axios.post(dashboardUrl.readImage, { imgSaveFileNm: this.frames[index].fileName || this.frames[index].imgSaveFileNm }).then(res => {
				this.activeImgSrc = `data:image/jpg;base64,${res.data.imgByte}`;
			});

			const targetElem = this.$refs.frameListRef.querySelector(`.img-wrapper:nth-child(${this.activeIndex + 1})`);
			if (targetElem) {
				targetElem.scrollIntoView({
					behavior: 'smooth',
					inline: 'center',
				});
			}

			classList.add('loading-details');

			await this.getOneObjectTargets(this.frames[index].frameImgSeq);

			classList.remove('loading-details');
			this.$refs.toggleBtnRef.focus();
		},
	},
};
</script>

<style scoped>
.rootVM {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	color: black;
	font-size: 1.4em;
	background: rgba(0, 0, 0, 0.4);
	z-index: 100;
}

.rootVM .fa-solid {
	font-family: 'Font Awesome 6 Free';
	cursor: pointer;
}

.rootVM .content {
	display: flex;
	flex-direction: column;
	width: 120vh;
	max-width: calc(100vw - 40px);
	height: 100%;
	max-height: calc(100vh - 40px);
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	border-radius: 6px;
	z-index: 102;
	padding: 20px;
}

.rootVM .content .header {
	display: flex;
	flex-direction: column;
	justify-self: center;
}

.rootVM .content .header .top {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

.rootVM .content .header .top .text {
	font-weight: bold;
}

.rootVM .content .header .top .text span.misdect {
	color: green;
}

.rootVM .content .header .top i {
	margin-left: auto;
	font-size: 1.6em;
}

.rootVM .content .header .bottom {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	min-height: 22px;
	font-size: 0.8em;
	font-weight: normal;
	margin-bottom: 16px;
}

.rootVM .content .header .bottom .object:after,
.rootVM .content .header .bottom .object .target:after {
	content: ', ';
}

.rootVM .content .header .bottom .object:last-of-type:after,
.rootVM .content .header .bottom .object .target:last-child:after {
	content: '';
}

.rootVM .content .header .bottom .toggle {
	display: flex;
	align-items: center;
	min-width: 142px;
	height: 20px;
	font-size: 1em;
	background: white;
	border: 0;
	font-size: 1.2em;
	margin-left: auto;
	cursor: pointer;
}

.rootVM .content .header .bottom .toggle .switch {
	position: relative;
	width: 38px;
	height: 18px;
	border: 1px solid black;
	border-radius: 980px;
	background: white;
	transition: 0.3s;
	margin: 0;
	margin-right: 4px;
	cursor: pointer;
}

.rootVM .content .header .bottom .toggle .switch:before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 2px;
	transform: translate(0, -50%);
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #ddd;
	border: 1px solid black;
	transition: all 0.3s;
}

.rootVM .content .header .bottom .toggle.active .switch {
	border: 1px solid rgb(44, 114, 175);
}

.rootVM .content .header .bottom .toggle.active .switch:before {
	left: 20px;
	background: rgb(44, 114, 175);
	border: 1px solid rgb(44, 114, 175);
}

.rootVM .content .video-body {
	height: calc(100% - 80px);
}

.rootVM .content .frame-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	overflow: hidden;
}

.rootVM .content .frame-body > .img-wrapper {
	flex: 1;
	position: relative;
	width: 100%;
	height: 100%;
	max-height: calc(100vh - 100px);
	overflow: hidden;
}

.rootVM .content .frame-body > .img-wrapper img {
	width: 100%;
	height: 100%;
}

.rootVM .content .frame-body > .img-wrapper .fa-solid {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	font-size: 1.6em;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	background: rgba(255, 255, 255, 0.6);
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.rootVM .content .frame-body > .img-wrapper .fa-chevron-left {
	left: 20px;
}

.rootVM .content .frame-body > .img-wrapper .fa-chevron-right {
	right: 20px;
}

.rootVM .content .frame-body .img-list-wrapper {
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 120px;
}

.rootVM .content .frame-body .img-list-wrapper > .fa-solid {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	font-size: 1.4em;
}

.rootVM .content .frame-body .img-list-wrapper .img-list {
	display: flex;
	align-items: center;
	gap: 12px;
	width: 100%;
	height: 100%;
	padding: 10px;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
}

.rootVM .content .frame-body .img-list-wrapper .img-list .fa-solid {
	display: none;
	font-size: 1.6em;
	margin: auto;
}

.rootVM .content .frame-body .img-list-wrapper > .fa-solid {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.rootVM .content .frame-body .img-list-wrapper > .fa-chevron-left {
	left: 24px;
}

.rootVM .content .frame-body .img-list-wrapper > .fa-chevron-right {
	right: 24px;
}

.rootVM .content .frame-body .img-list-wrapper .img-list.loading-list .fa-solid {
	display: block;
}

.rootVM .content .frame-body .img-list-wrapper .img-list .end-content {
	display: none;
	flex-shrink: 0;
	width: 100px;
	word-break: keep-all;
	text-align: center;
	line-height: 1.6em;
	color: #ddd;
	margin-right: 200px;
}

.rootVM .content .frame-body .img-list-wrapper .img-list.end .end-content {
	display: block;
}

.rootVM .content .frame-body .img-list-wrapper .img-list .img-wrapper {
	width: 120px;
	min-width: 120px;
	height: 100%;
	scroll-snap-align: center;
	cursor: pointer;
}

.rootVM .content .frame-body .img-list-wrapper .img-list .img-wrapper.active {
	border: 4px solid blue;
}

.rootVM .content .frame-body .img-list-wrapper .img-list .img-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (max-height: 800px) {
	.rootVM .content .frame-body .img-wrapper .active-img img {
		object-fit: contain;
		display: none;
	}
}
</style>
